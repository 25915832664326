import NavigationOnsiteAccordion from './navigation-onsite-accordion.js';
import Logger from '@core/scripts/components/logger';
import fetchWithCSRF from '@core/scripts/helper/fetch-with-csrf';
import JshModule from '@core/scripts/helper/jsh-module';

const {moduleScope, config} = JshModule('header/navigation-onsite');
config.onsite = true;

getOnsiteNavigation();

function getOnsiteNavigation() {
    fetchWithCSRF(`${config.ajaxUrl}?navigationItemId=${config.id}`, {
        method: 'GET'
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.text();
        })
        .then((data) => {
            moduleScope.insertAdjacentHTML('beforeend', data);
            NavigationOnsiteAccordion(moduleScope, config);

            Logger.debug('[ONSITE] initialized at ', moduleScope, 'with', config);
        })
        .catch((error) => {
            return Logger.error('Could not load onsite navigation!', error);
        });
}